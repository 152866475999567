const productController = {
  productLink: document.querySelectorAll('.product__item-link'),
  productTitle: document.querySelectorAll('.product__item-title'),
  init() {
    this.setAttribute()
  },
  setAttribute() {
    this.productLink.forEach((link, index) => {
      link.setAttribute('aria-label', `${this.productLink[index].innerText} ${this.productTitle[index].innerText}`)
    })
  }
}

productController.init()